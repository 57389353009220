@font-face {
  font-family: "Gotham Condensed";
  src: url("../fonts/subset-GothamCondensed-Bold.woff2") format("woff2"),
    url("../fonts/subset-GothamCondensed-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
  font-display: fallback;
}

@import url("https://fonts.googleapis.com/css2?family=Amatic+SC:wght@700&family=Titillium+Web:wght@400;600&display=swap");

body,
html {
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  margin: 0;
  overscroll-behavior: none;
  &.modal-open {
    overflow-y: hidden;
    header .user {
      pointer-events: none;
    }
  }
}

#root,
main {
  height: 100%;
  //min-height: -webkit-fill-available
}

.inactive .ddcontainer {
  pointer-events: none;
  opacity: 0.5;
}

.highlighted svg {
  filter: blur(5px);
}

* {
  box-sizing: border-box;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

.scrollbar-container {
  width: 100%;
}

.btn-outline-primary:hover {
  color: $primary;
  background-color: rgba($primary, 0.1);
  border-color: #f5a800;
}

.icon-sound {
  position: absolute;
  z-index: 0;
  right: 15px;
  bottom: 15px;
  cursor: pointer;
  @include media-breakpoint-down(sm) {
    bottom: 130px;
  }
}

// Cookies
.CookieConsent {
  align-items: baseline;
  background: rgba($yellow, 1);
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  font-size: 0.9rem;

  color: $brown;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  left: 0px;
  position: fixed;
  width: 300px;
  z-index: 0;
  padding:15px;
  bottom: 90px !important;
  a {
    color: $brown;
    font-weight:600;
  }

  .btn-consent {
    font-size: 1.3rem;
    border: 0;
    background-color: rgba($white, 0.6);
    &:hover {
      background-color: rgba($white, 1)
    }
  }


}

// TEMP

.temp-menu {
  opacity: 0.3;
  right: 10px;
  bottom: 15%;
  z-index: 0;

  span {
    cursor: pointer;
    margin: 0 10px;
  }
}
