.pages {
  padding-top: 50px;

  .wrapper {
    padding: 90px 130px 90px 130px;
    background-color: $white;

    @include media-breakpoint-down(md) {
      padding: 40px;
    }

    .button-absolute {
      @include media-breakpoint-down(sm) {
        position: absolute;
        bottom: -70px;
        width: 100%;
        text-align: center;
        .btn {
          width: 75%;
        }
      }
    }

    .entry {
      margin-bottom: 100px;

      >div {
        @include media-breakpoint-up(md) {
          padding-right:4rem;
        }
        
      }

      &__icons {
        > div {
          @include media-breakpoint-down(md) {
            margin-top: 60px;
          }
        }
        h3 {
          position: relative;
          height: 50px;
          font-size: 1.8rem;
          @include media-breakpoint-down(md) {
            font-size: 1.6rem;
            height:auto;
            margin-top:15px;
          }

          &:before {
            content: "";

            height: 50px;
            width: 50px;
            background-size: 50px 50px;
            position: absolute;
            z-index: 1;
            left: -60px;
            @include media-breakpoint-down(md) {
              left: 50%;
              transform: translateX(-50%);
              top: -55px;
            }
          }
          &.green {
            &:before {
              background: url("/assets/images/icon-cebelca-green.svg") no-repeat;
            }
          }

          &.yellow {
            &:before {
              background: url("/assets/images/icon-cebelca.svg") no-repeat;
            }
          }
        }
      }
    }

    &.variant-two {
      h2 {
        font-family: $gotham;
        font-size: 1.6rem;
        @include media-breakpoint-down(md) {
          font-size: 1.3rem;
        }
      }
    }
  }
}
