// Colors
$yellow: #f4c343;
$yellow-sec: #f5a800;
$yellow-alt: #C18400;
$brown: #4c240e;
$creme: #faf7f5;
$beige: #c2b69f;
$green: #357642;
$green-sec: #798F33;
$green-alt: #5C6D24;
$white: #fff;
$body-bg: $white;
$body-color: #4c240e;
$red: #6d2424;


// Fonts
$gotham: 'Gotham Condensed';
$amatic: 'Amatic SC', cursive;
$titillium: 'Titillium Web', sans-serif;



$font-family-sans-serif: $titillium;
$primary: $yellow-sec;
$secondary: $brown;

$headings-font-family: $amatic;
$headings-color: $brown;

$utilities: (
  "custom-margin-top": (
    property: margin-top,
    class: mt,
    values: (
      0: 0,
      1: 0.4rem,
      2: 0.8rem,
      3: 1.2rem,
      4: 1.6rem,
      5: 2rem,
      6: 5rem,
    ),
  ),
  "opacity": (
    property: opacity,
    values: (
      0: 0,
      25: .25,
      50: .5,
      75: .75,
      90: .90,
      100: 1,
    )
  ),
);

$theme-colors: (
  "primary": $yellow-sec,
  "secondary": $brown,
  "green":    $green-sec,
  "green-alt": $green-alt,
  "beige": $beige,
  "yellow": $yellow-sec,
  "yellow-alt": $yellow-alt,
  "red" : $red,
);



$btn-focus-box-shadow: 0;
$btn-font-family: $gotham;
$btn-font-size: 1.8rem;
$btn-padding-x: 1rem;
$btn-padding-y: 0.3rem;
$btn-border-width: 3px;

$border-radius: 2.4rem;
$tooltip-max-width: 290px;
$tooltip-bg: $white;
$tooltip-color: $brown;
$tooltip-padding-y: 20px;
$tooltip-padding-x: 20px;
$tooltip-opacity: 1;

$input-bg: $white;
$input-border-color: $yellow;
$input-border-width: 3px;
$input-color: $brown;
//$form-floating-label-opacity: 0.5;
$input-padding-x: 1.5rem;
$form-check-input-width: 1.5em;
$form-check-input-border:  3px solid $yellow;
$form-check-input-border-radius:  50%;

//@import "../node_modules/bootstrap/scss/bootstrap.scss";
@import "~bootstrap/scss/bootstrap";