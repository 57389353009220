footer {
  background: #fff;
  position: relative;
  z-index: 1;

  @include media-breakpoint-down(sm) {
    text-align: center;
  }

  .social-share {
    img {
      width: 25px;
      @include media-breakpoint-down(sm) {
        width:20px;
      }
    }
    @include media-breakpoint-down(sm) {
      margin: 0.5rem 0;
    }
  }

  .links {
    @include media-breakpoint-down(sm) {
      margin: 0.5rem 0;
      font-size: 0.8rem;
    }

    a {
      color: $brown;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .escapebox {
    img {
      height:50px;
      width:50px;
    }
    @include media-breakpoint-down(sm) {
      margin: 0.5rem 0;
      img {
        width: 30px;
        height:30px;
      }
    }
  }
}
