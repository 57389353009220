$headerHeightDesktop: 75px;
$headerHeightMobile: 30px;

.game-wrapper {
  height: 100%;
}
.wrapper-droppable {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 100%;

  @include media-breakpoint-down(sm) {
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    min-width: 100%;
  }

  .scrollable {
    min-height: 100%;
  }

  svg {
    transform-origin: center;
    @include media-breakpoint-up(sm) {
      min-height: 100%;
      min-width: 100%;
      height: auto;
      width: auto;
      position: absolute;
      top: -100%;
      bottom: -100%;
      left: -100%;
      right: -100%;
      margin: auto;
    }

    @include media-breakpoint-down(sm) {
      height: 100vh;
      width: auto;
    }

    #trees {
      > g {
        display: none;
        opacity: 0;
        height: 0;
        //transform: scale(0.8);
        transition: opacity 3s, visibility 3s, height 3s, transform 3s;
        //transform-origin: center;

        @for $i from 1 through 40 {
          &:nth-child(#{$i}) {
            transition-delay: 150ms * $i;
          }
        }
      }

      .unfold {
        //transform: scale(1);
        opacity: 1;
        display: block;
        height: 100%;
        //transform-origin: center;
      }
    }
  }
}

.wrapper-inside {
  height: 100%;
  padding-top: 70px;
  min-height: -webkit-fill-available;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @include media-breakpoint-down(sm) {
    padding-top: 30px;
  }

  .upper {
    position: relative;
    justify-content: normal;
    .progress-total {
      position: relative;
      svg.svg-progress {
        overflow: visible;
        filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.1));
        max-width: 800px;
        @include media-breakpoint-down(md) {
          max-width: 90%;
        }

        #textNum {
          display: none;
          transform-origin: center;
          transform-box: fill-box;
          animation: leaves 0.5s ease-out alternate;
          &.show {
            display: block;
          }
        }
      }

      svg.svg-bee {
        position: absolute;
        z-index: 2;
        top: 50%;
        left: 30px;
        transform: translateY(-50%);
      }

      .milestone-icon {
        opacity: 0.4;
        transition: opacity 0.5s ease-in-out;

        &.achived {
          opacity: 1;
        }
      }

      #Milestone1 {
        transform: translateX(100px);
      }

      #Milestone2 {
        transform: translateX(130px);
      }

      #Milestone3 {
        transform: translateX(125px);
      }

      #Milestone4 {
        transform: translateX(87px);
      }

      #Milestone5 {
        transform: translateX(51px);
      }
      #Milestone6 {
        transform: translateX(14px);
      }
      ito .counter-progress {
        color: $brown;
        font-size: 1.3rem;
        margin-bottom: -10px;
        @include media-breakpoint-down(sm) {
          font-size: 1rem;
          margin-bottom: -5px;
        }
        span {
          font-weight: 700;
        }
      }
    }
  }

  .middle {
    .countdown {
      display: inline-block;
      position: relative;
      background-color: rgba($yellow, 0.6);

      p {
        font-weight: 600;
        font-size: 1.2rem;
      }

      h2 {
        font-family: $gotham;
        span {
          font-size: 3rem;
          @include media-breakpoint-down(sm) {
            font-size: 2rem;
          }
        }
      }
    }
  }

  .bottom {
    .draggable {
      margin-bottom: 80px;

      @include media-breakpoint-down(sm) {
        margin-bottom: 20px;
      }

      .treeBox {
        display: inline-block;
        text-align: center;

        img.treeImage {
          width: 110px;
          @include media-breakpoint-down(sm) {
            width: 70px;
          }
        }

        img.icon {
          width: auto;
          margin-left: 8px;
        }

        p {
          color: $brown;
          position: relative;
        }
      }
    }
  }
}

.wrapper-outside {
  z-index: 0;
  margin: 0;
  width: 1200px;
  position: absolute;
  bottom: 0;
  left: 50%;

  transform: translateX(-50%);
  background-color: transparent;

  @include media-breakpoint-down(md) {
    position: relative;
    background-color: $white;
  }

  @include media-breakpoint-down(xl) {
    width: 100%;
  }

  .btn {
    width: 100%;
    font-size: 1.1rem;
    @include media-breakpoint-up(md) {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}

@keyframes leaves {
  0% {
    transform: scale(4);
  }
  100% {
    transform: scale(1);
  }
}
