.backdrop {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding-top: 70px;
  background: rgba($black, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: hidden;
  backdrop-filter: blur(5px);
  @include media-breakpoint-down(sm) {
    padding-top: 30px;
  }

  &.complete {
    background: linear-gradient(
      0deg,
      rgba(253, 224, 133, 0.9) 0%,
      rgba(222, 152, 0, 0.9) 100%
    );
    opacity: 0.95;

    .modal-framer {
      background: transparent;
      max-height: 100%;

      .icon-close {
      }
    }
  }

  &.milestone {
    background: rgba($black, 0.5);
  }
}

.modal-framer {
  width: clamp(60%, 700px, 90%);
  //max-width: 1200px;
  max-height: 95%;
  position: relative;

  overflow: hidden;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba($white, 0.8);
  transition: background-color 1000ms linear;
  z-index: 1;

  @include media-breakpoint-down(sm) {
    width: 100%;
    height: 100%;
    max-height: 100%;
    border-radius: 0 !important;
    overflow-y: auto;
  }

  .icon-close {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    z-index: 1;
    @include media-breakpoint-down(sm) {
      width: 60px;
    }
  }

  .loading-animation {
    position: absolute;
    z-index: 5;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .modal-text.loading {
    opacity: 0.3 !important;
  }

  &.animating {
    background-color: transparent;
    //overflow:hidden;
  }

  #kolesar-anim {
    width: 300px;
  }
}

.modal-animation {
  svg {
    max-width: 230px;
    overflow: visible;

    @include media-breakpoint-down(sm) {
      max-width: 200px;
    }
  }
}

#tooltip-modal.tooltip {
  .tooltip-inner {
    background-color: $white;
    border: 3px solid $primary;
  }
  .tooltip-arrow {
    display: none;
  }
}

.milestone-numbers {
  h1,
  h2 {
    font-family: $gotham;
  }

  h2 {
  }
}

.icon-milestone {
  filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.1));
  width: 200px;
}

.usps {
  img {
    height: 90px;
    @include media-breakpoint-down(sm) {
      height: 70px;
    }
  }

  p {
    @include media-breakpoint-down(sm) {
      font-size: 80%;
    }
  }
}

.usps-modal {
  .btn {
    width: 100%;
    font-size: 1.1rem;
  }
}
