header {
    background: #fff;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 2;

    
    .language-picker {
        font-size:1.2rem;
        color: $brown;
        @include media-breakpoint-down(sm) {
            font-size: 0.8rem;
        }

        button {
            font-size: 1.2rem;
            outline:0;
            opacity: 0.5;
            text-decoration: none;
            @include media-breakpoint-down(sm) {
                font-size: 0.8rem;
            }
            &.active {
                opacity: 1;
            }
        }
    }

    .logo {
        img {
            width: 100px;
            @include media-breakpoint-down(sm) { 
                width: 60px;
            }
        }
    }

    .user {
        font-family: $amatic;
        font-size: 1.6rem;
        font-weight: 700;
        @include media-breakpoint-down(sm) {
            font-size: 1.2rem;
        }
    }
}
